import { MAINTAIN_PERMISSION, STERILIZATION_SPECIFICATIONS_COOKERCAPACITIES } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Cooker Capacity", "search-by": "CookerNo,Cooker", "ph-search-by": "Search by Cooker ID, Cooker" } }, [_c("url-field", { key: "CookerNo", attrs: { "base-url": "/sterilization-specifications/cooker-capacities", "data-index": "cookerNo", "sorter": true, "title": "Cooker ID", "width": 105, "clickable": _vm.can(_vm.permissions.maintain) } }), _c("text-field", { key: "Cooker", attrs: { "data-index": "cooker", "title": "Cooker", "sorter": true } }), _c("icon-field", { key: "Hydro", attrs: { "data-index": "hydro", "title": "Hydro", "align": "center", "render-icon": _vm.renderIcon, "sorter": true } }), _c("text-field", { key: "CanSize", attrs: { "data-index": "canSize", "title": "Can Size", "sorter": true } }), _c("text-field", { key: "Capacity", attrs: { "data-index": "capacity", "title": "Cooker (Outer)", "align": "right", "sorter": true } }), _c("text-field", { key: "PCCapacity", attrs: { "data-index": "pcCapacity", "title": "P.C.", "align": "right", "sorter": true } }), _c("text-field", { key: "ACCapacity", attrs: { "data-index": "acCapacity", "title": "A.C.", "align": "right", "sorter": true } }), _c("text-field", { key: "TotalCapacity", attrs: { "data-index": "totalCapacity", "title": "Total (Outer)", "align": "right", "sorter": true } }), _c("text-field", { key: "CapacityInner", attrs: { "data-index": "capacityInner", "title": "Cooker (Inner)", "align": "right", "sorter": true } }), _c("text-field", { key: "TotalCapacityInner", attrs: { "data-index": "totalCapacityInner", "title": "Total (Inner)", "align": "right", "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListCookerCapacities",
  inject: ["can"],
  data() {
    return {
      permissions: {
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  methods: {
    renderIcon(value) {
      if (!value) {
        return "";
      }
      return "check-circle";
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListCookerCapacities = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-cooker-capacities" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "page": _vm.page, "name": "sterilization.cooker-capacities", "api-url": _vm.apiUrl, "filter-route": "/sterilization-specifications/cooker-capacities/advanced-search", "create-route": "/sterilization-specifications/cooker-capacities/create", "edit-route": "/sterilization-specifications/cooker-capacities/:id", "list": _vm.ListCookerCapacities } }), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "CookerCapacities",
  data() {
    return {
      ListCookerCapacities,
      page: STERILIZATION_SPECIFICATIONS_COOKERCAPACITIES,
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "cookercapacity",
          title: "Cooker Capacity",
          path: "/sterilization-specifications/cooker-capacities"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
